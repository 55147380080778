<template>
  <section
    id="wrapper"
    :class="[
      'login-register login-sidebar',
      isMobile ? 'bg-responsive' : 'bg-login',
    ]"
  >
    <div class="content">
      <div class="container">
        <div class="row align-items-center">
          <div class="contents">
            <div id="content" style="background-color: transparent">
              
              <div>
                <div>
                  <div class="login-box card">
                    <div class="card-body">
                      <form
                        class="form-horizontal form-material"
                        id="loginform"
                        @submit.prevent="login"
                        autocomplete="off"
                      >
                        <a href="javascript:void(0)" class="text-center db">
                          <img
                            src="@/assets/img/logo.png"
                            style="width: 80px; height: 100px"
                            alt="Home"
                          />
                          <br />
                        </a>

                        <div class="form-group m-t-40">
                          <div class="col-xs-12">
                            <Input
                              v-model="username"
                              name="username"
                              type="text"
                              placeholder="Username"
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-xs-12">
                            <Input
                              :type="passwordFieldType"
                              v-model="password"
                              name="password"
                              placeholder="Password"
                              required
                            />
                            <button type="password" @click="switchVisibility">
                              melihat / menutup
                            </button>
                          </div>
                        </div>
                        
                        <div class="form-group text-center m-t-20">
                          <div class="col-xs-12">
                            <button
                              class="
                                btn btn-info btn-lg btn-block
                                text-uppercase
                                waves-effect waves-light
                              "
                              type="submit"
                              :disabled="isLoading"
                              @click="masuk"
                            >
                              {{ isLoading ? "Please Wait..." : "Log In" }}
                            </button>
                          </div>
                        </div>
                        <div v-if="message !== ''" class="form-group">
                          <div class="alert alert-danger">
                            {{ $t(message) }}
                          </div>
                        </div>
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>
<script>
import Authencrypt from "../../mixins/authencrypt";
import Input from "../../components/input/input";

export default {
  components: {
    Input,
  },
  mixins: [Authencrypt],
  data() {
    return {
      username: "",
      password: "",
      window: {
        width: 0,
        height: 0,
      },
      isMobile: false,
      passwordFieldType: "password",
    };
  },
  computed: {
    message() {
      return this.$store.state.auth.message;
    },
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
    base() {
      return this.baseUrl;
    },
  },
  methods: {
    // login() {
    //   const credentials = {
    //     username: this.username,
    //     password: this.password,
    //   };

    //   const objData = JSON.stringify(credentials);
    //   this.$validator.validateAll().then((success) => {
    //     if (success) {
    //       this.$store.dispatch("auth/login", objData);
    //     }
    //   });
    // },
    masuk() {
      const credentials = {
        username: this.username,
        password: this.password,
      };

      const objData = JSON.stringify(credentials);
      this.$validator.validateAll().then((success) => {
        if (success) {
          this.$store.dispatch("auth/login", objData);
        }
      });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    mobileCondition() {
      if (this.window.width <= 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    database_sisminkada() {
      window.open("http://103.131.105.2:2354/old2019/");
    },
    meeting_room() {
      window.open("http://103.131.105.2:2354/meeting-room/unit_list.php");
    },
    penghargaan() {
      window.open("http://103.131.105.2:2354/penghargaan/penghargaan_list.php");
    },
    bend26a() {
      window.open("http://103.131.105.2:2354/bend26a/login.php");
    },
    pustaka() {
      window.open("http://slim.sisminkada.net/data/login.php");
    },
    web_mail() {
      window.open("https://mail.jogjaprov.go.id/");
    },
    lampiran_skp() {
      window.open("http://103.131.105.2:2354/skp/login.php");
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.mobileCondition();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.bg-responsive {
  background-color: #e6e5e6;
  height: 100%;
}
</style>

